<script setup>
import { ref, onMounted } from 'vue'
import Controller from '@/controller'
import Select from '@/components/Select.vue'
import ArchiveTable from '@/components/ArchiveTable.vue'

const $context = ref({})

const Load = async () => {
	const { rows, last } = await Controller.Upload.Query({ ...$context.value.params, 'page': $context.value.params['pagination']['page'] })

	$context.value.rows = rows
	$context.value.params['pagination']['last'] = last
}

$context.value.columns = [
	{
		'key': 'name',
		'title': 'Name',
		'sortable': true
	},
	{
		'key': 'client_name',
		'title': 'Client',
		'sortable': false
	},
	{
		'key': 'filename',
		'title': 'File',
		'sortable': false,
		'icon': 'fa-solid fa-arrow-turn-down',
		action: (row) => {
			window.open(row['file'], '_blank')
		}
	}
]

$context.value.params = {
	'sort': {
		'column': 'name',
		'descending': false
	},
	'pagination': {
		'items': 20,
		'page': 1
	},
	'search': '',
	'filters': {
		'client_id': null
	}
}

$context.value.actions = {
	'delete': {
		'name': 'Delete',
		action: async (selected) => {
			if (confirm('Delete selected items?')) {
				const data = await Controller.Upload.Delete({ 'uploads': selected })

				if (data) {
					Load()
				}
			}
		}
	}
}

$context.value.rows = []
$context.value.clients = []

onMounted(async () => $context.value.clients = await Controller.Client.List())
</script>

<template>
	<ArchiveTable :columns="$context.columns" :rows="$context.rows" :params="$context.params" :actions="$context.actions" @change="Load()">
		<Select v-model="$context.params['filters']['client_id']" :options="$context.clients" placeholder="Filter Client"/>
	</ArchiveTable>
</template>
