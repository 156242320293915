import '@/scss/main.scss'
import { createApp } from 'vue'
import VueCookies from 'vue-cookies'
import Axios from 'axios'
import Router from '@/router'
import Store from '@/store'
import Main from '@/Main.vue'

Axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_ENDPOINT

if ($cookies.get('token')) {
	Axios.defaults.headers.common['Authorization'] = 'Bearer ' + $cookies.get('token')
}

Store.dispatch('SetConfig')
Store.dispatch('SetSession')

createApp(Main)
.use(Router)
.use(Store)
.use(VueCookies, { expires: '7d', path: '/', domain: '', secure: '', 'sameSite': 'Lax' })
.mount('#app')
