<script setup>
const emit = defineEmits(['update:modelValue', 'change'])

const Change = (value) => {
	emit('update:modelValue', value)
	emit('change', value)
}

defineProps(['modelValue', 'last'])
</script>

<template>
	<ul class="pagination" v-if="last > 1">
		<li class="previous" v-if="modelValue > 1">
			<a href="" @click.prevent="Change(modelValue - 1)"><i class="fa-solid fa-caret-left"></i></a>
		</li>
		<li v-if="modelValue > 3">
			<a href="" @click.prevent="Change(1)">1</a>
		</li>
		<li v-if="modelValue > 4">
			<a href="" @click.prevent="Change(2)">2</a>
		</li>
		<li class="seperator" v-if="modelValue > 5">...</li>
		<li v-if="modelValue > 2">
			<a href="" @click.prevent="Change(modelValue - 2)">{{ modelValue - 2 }}</a>
		</li>
		<li v-if="modelValue > 1">
			<a href="" @click.prevent="Change(modelValue - 1)">{{ modelValue - 1 }}</a>
		</li>
		<li class="current">
			<a href="" @click.prevent="Change(modelValue)">{{ modelValue }}</a>
		</li>
		<li v-if="modelValue < last - 2">
			<a href="" @click.prevent="Change(modelValue + 1)">{{ modelValue + 1 }}</a>
		</li>
		<li v-if="modelValue < last - 3">
			<a href="" @click.prevent="Change(modelValue + 2)">{{ modelValue + 2 }}</a>
		</li>
		<li class="seperator" v-if="modelValue < last - 4">...</li>
		<li v-if="modelValue < last - 1">
			<a href="" @click.prevent="Change(last - 1)">{{ last - 1 }}</a>
		</li>
		<li v-if="modelValue < last">
			<a href="" @click.prevent="Change(last)">{{ last }}</a>
		</li>
		<li class="next" v-if="modelValue < last">
			<a href="" @click.prevent="Change(modelValue + 1)"><i class="fa-solid fa-caret-right"></i></a>
		</li>
	</ul>
</template>
