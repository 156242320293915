import Axios from 'axios'

const Query = async (payload) => {
	const { data } = await Axios.post('clients', payload)

	return data
}

const Add = async (payload) => {
	const { data } = await Axios.post('clients/add', payload)

	return data
}

const Update = async (ID, payload) => {
	const { data } = await Axios.post('clients/' + ID, payload)

	return data
}

const Client = async (ID) => {
	const { data } = await Axios.get('clients/' + ID)

	return data
}

const List = async () => {
	const list = {}

	const payload = {
		'sort': {
			'column': 'name',
			'descending': false
		},
		'pagination': {
			'items': -1,
			'page': 1
		}
	}

	const { rows } = await Query(payload)

	for (let row of rows) {
		list[row['id']] = row['name']
	}

	return list
}

export default { Query, Add, Update, Client, List }
