<script setup>
import { ref, computed, watch } from 'vue'
import Store from '@/store'
import Button from '@/components/Button.vue'
import Message from '@/components/Message.vue'
import DataInput from '@/components/DataInput.vue'

const emit = defineEmits(['submit'])
const $context = ref({})

const HandleBankSelector = () => {
	if (!$context.value.data['bank']['selector']) return

	let i = $context.value.data['bank']['selector']

	$context.value.data['bank']['name'] = Store.state.config['banks'][i]
	$context.value.data['bank']['switf'] = Store.state.config['switf'][i]
}

const Submit = () => {
	$context.value.error = null
	$context.value.success = null

	if ($context.value.data['name'].length < 3) {
		return $context.value.error = 'Client name must be at least 3 characters.'
	}

	if (!$context.value.data['type']) {
		return $context.value.error = 'Client type is required.'
	}

	emit('submit')
}

$context.value.inputs = {
	'base': {
		'name': {
			'type': 'text',
			'label': 'Name:',
			'placeholder': ''
		},
		'type': {
			'type': 'select',
			'label': 'Type:',
			'placeholder': 'Select type',
			'options': Store.state.config['client_types']
		},
		'legal_prefix': {
			'type': 'select',
			'label': 'Legal prefix:',
			'placeholder': 'Select legal prefix',
			'options': Store.state.config['legal_prefixes']
		},
		'legal_name': {
			'type': 'text',
			'label': 'Legal name:',
			'placeholder': ''
		},
		'registration_number': {
			'type': 'text',
			'label': 'Registration number:',
			'placeholder': ''
		},
		'vat_number': {
			'type': 'text',
			'label': 'VAT number:',
			'placeholder': ''
		},
		'legal_address': {
			'type': 'text',
			'label': 'Legal address:',
			'placeholder': ''
		},
		'actual_address': {
			'type': 'text',
			'label': 'Actual address:',
			'placeholder': ''
		}
	},
	'contact': {
		'name': {
			'type': 'text',
			'label': 'Name:',
			'placeholder': ''
		},
		'phone': {
			'type': 'text',
			'label': 'Phone:',
			'placeholder': ''
		},
		'email': {
			'type': 'text',
			'label': 'Email:',
			'placeholder': ''
		}
	},
	'bank': {
		'selector': {
			'type': 'select',
			'label': 'Bank:',
			'placeholder': 'Select bank',
			'options': Store.state.config['banks']
		},
		'name': {
			'type': 'text',
			'label': 'Name:',
			'placeholder': ''
		},
		'account_number': {
			'type': 'text',
			'label': 'Account Number:',
			'placeholder': ''
		},
		'switf': {
			'type': 'text',
			'label': 'Switf:',
			'placeholder': ''
		}
	}
}

$context.value.data = {
	'name': '',
	'type': 'legal',
	'legal_prefix': null,
	'legal_name': '',
	'registration_number': '',
	'vat_number': '',
	'legal_address': '',
	'actual_address': '',
	'contact': {
		'name': '',
		'phone': '',
		'email': ''
	},
	'bank': {
		'selector': null,
		'name': '',
		'account_number': '',
		'switf': ''
	}
}

$context.value.error = null
$context.value.success = null

defineProps(['button'])
defineExpose({ $context })

watch(computed(() => $context.value.data['bank']['selector']), () => HandleBankSelector())
</script>

<template>
	<div class="client-form">
		<div>
			<DataInput :inputs="$context.inputs['base']" :data="$context.data"/>
		</div>
		<div>
			<span>Contact details:</span>
			<DataInput :inputs="$context.inputs['contact']" :data="$context.data['contact']"/>
		</div>
		<div>
			<span>Bank details:</span>
			<DataInput :inputs="$context.inputs['bank']" :data="$context.data['bank']"/>
		</div>
		<div>
			<Message :properties="{ class: 'message-error', icon: 'fa-solid fa-triangle-exclamation', message: $context.error }"/>
			<Message :properties="{ class: 'message-success', icon: 'fa-regular fa-circle-check', message: $context.success }"/>
		</div>
		<div>
			<Button :text="button.text" class="default" @click="Submit()"/>
		</div>
	</div>
</template>
