import Axios from 'axios'

const Query = async (payload) => {
	const { data } = await Axios.post('invoices', payload)

	return data
}

const Add = async (payload) => {
	const { data } = await Axios.post('invoices/add', payload)

	return data
}

const Update = async (ID, payload) => {
	const { data } = await Axios.post('invoices/' + ID, payload)

	return data
}

const Invoice = async (ID) => {
	const { data } = await Axios.get('invoices/' + ID)

	return data
}

const Printable = async (ID) => {
	const { data } = await Axios({
		url: 'invoices/' + ID + '/printable',
		method: 'GET',
		responseType: 'blob'
	})

	return data
}

const Dump = async (params) => {
	const { data } = await Axios({
		url: 'invoices/dump',
		method: 'GET',
		responseType: 'blob',
		params
	})

	return data
}

export default { Query, Add, Update, Invoice, Printable, Dump }
