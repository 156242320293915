<script setup>
import { ref } from 'vue'
import Controller from '@/controller'
import ArchiveTable from '@/components/ArchiveTable.vue'

const $context = ref({})

const Load = async () => {
	const { rows, last } = await Controller.User.Query({ ...$context.value.params, 'page': $context.value.params['pagination']['page'] })

	$context.value.rows = rows
	$context.value.params['pagination']['last'] = last
}

$context.value.columns = [
	{
		'key': 'name',
		'title': 'Name',
		'sortable': true
	},
	{
		'key': 'email',
		'title': 'Email',
		'sortable': false
	},
	{
		'key': 'sessions',
		'title': 'Sessions',
		'sortable': false
	}
]

$context.value.params = {
	'sort': {
		'column': 'name',
		'descending': false
	},
	'pagination': {
		'items': 20,
		'page': 1
	},
	'search': ''
}

$context.value.actions = {
	'delete': {
		'name': 'Delete',
		action: (selected) => {
			if (confirm('Delete selected items?')) {
				console.log('delete', selected)
			}
		}
	}
}

$context.value.rows = []
</script>

<template>
	<ArchiveTable :columns="$context.columns" :rows="$context.rows" :params="$context.params" :actions="$context.actions" @change="Load()"/>
</template>
