<script setup>
import { ref, computed, watch } from 'vue'
import Store from '@/store'
import Controller from '@/controller'
import Button from '@/components/Button.vue'

const $context = ref({})

const HandleCollapse = () => {
	if (Store.state.session['settings']['collapsed_layout']) {
		document.body.classList.add('layout-collapsed')
	} else {
		document.body.classList.remove('layout-collapsed')
	}

	$context.value.icon = {
		'fa-solid': true,
		'fa-arrows-left-right-to-line': Store.state.session['settings']['collapsed_layout'],
		'fa-arrow-left': !Store.state.session['settings']['collapsed_layout']
	}
}

$context.value.menu = [
	{
		to: { name: 'clients-table' },
		icon: 'fa-solid fa-mug-hot',
		text: 'Clients'
	},
	{
		to: { name: 'invoices-table' },
		icon: 'fa-solid fa-file-invoice-dollar',
		text: 'Invoices'
	},
	{
		to: { name: 'uploads-table' },
		icon: 'fa-solid fa-paperclip',
		text: 'Uploads'
	},
	{
		to: { name: 'users-table' },
		icon: 'fas fa-key',
		text: 'Users'
	}
]

watch(computed(() => Store.state.session['settings']['collapsed_layout']), () => HandleCollapse(), { immediate: true })
</script>

<template>
	<div class="layout">
		<header>
			<div>
				<div>
					<i class="fa-solid fa-circle-user"></i>
					<span>{{ Store.state.session.User['email'] }}</span>
				</div>
				<div>
					<Button @click="Controller.Logout()" text="Logout" icon="fa-solid fa-power-off"/>
				</div>
			</div>
			<div>
				<div v-for="link in $context.menu">
					<router-link :to="link.to"><i :class="link.icon"></i><span>{{ link.text }}</span></router-link>
				</div>
			</div>
			<div>
				<Button @click="Store.state.session['settings']['collapsed_layout'] = !Store.state.session['settings']['collapsed_layout']" :icon="$context.icon"/>
			</div>
		</header>
		<div class="content">
			<slot/>
		</div>
	</div>
</template>
