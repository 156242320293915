<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import TextInput from '@/components/TextInput.vue'
import Select from '@/components/Select.vue'
import Table from '@/components/Table.vue'
import Pagination from '@/components/Pagination.vue'

const props = defineProps(['columns', 'rows', 'params', 'actions'])
const emit = defineEmits(['change'])
const $context = ref({})

const HandleChange = () => {
	props.params['pagination']['page'] = 1

	emit('change')
}

const HandleSelect = (checkboxes) => {
	$context.value.selected = []

	for (let ID in checkboxes) {
		if (!checkboxes[ID]) continue

		$context.value.selected.push(parseInt(ID))
	}
}

$context.value.options = {}

onMounted(() => {
	for (let key in props.actions) {
		$context.value.options[key] = props.actions[key]['name']
	}
})

watch(computed(() => props.params['sort']), () => HandleChange(), { deep: true, immediate: true })
watch(computed(() => props.params['search']), () => HandleChange())
watch(computed(() => props.params['filters']), () => HandleChange(), { deep: true })
</script>

<template>
	<div class="archive-table">
		<div>
			<div>
				<Select :options="$context.options" @change="actions[$event].action($context.selected)" placeholder="Actions"/>
			</div>
			<div>
				<slot/>
				<TextInput v-model="params['search']" type="text" placeholder="Search" icon="fa-solid fa-magnifying-glass" class="alternative"/>
			</div>
		</div>
		<div>
			<Table :columns="columns" :rows="rows" :sort="params['sort']" @select="HandleSelect($event)"/>
		</div>
		<div>
			<Pagination v-model="params['pagination']['page']" :last="params['pagination']['last']" @change="$emit('change')"/>
		</div>
	</div>
</template>
