<script setup>
import { ref, computed, watch } from 'vue'
import Checkbox from '@/components/Checkbox.vue'

const props = defineProps(['columns', 'rows', 'sort'])
const emit = defineEmits(['select'])
const $context = ref({})

const ResetCheckboxes = () => {
	$context.value.checkbox = false
	$context.value.checkboxes = {}

	props.rows.forEach(row => $context.value.checkboxes[row['id']] = false)
}

const Select = () => {
	emit('select', $context.value.checkboxes)
}

const SelectAll = () => {
	for (let key in $context.value.checkboxes) {
		$context.value.checkboxes[key] = $context.value.checkbox
	}
}

const Sort = (column) => {
	if (column == props.sort['column']) {
		props.sort['descending'] = !props.sort['descending']
	} else {
		props.sort['column'] = column
	}
}

watch(computed(() => props.rows), () => ResetCheckboxes(), { immediate: true })
watch(computed(() => $context.value.checkboxes), () => Select(), { deep: true })
watch(computed(() => $context.value.checkbox), () => SelectAll())
</script>

<template>
	<div class="table">
		<div>
			<div>
				<Checkbox id="select-all" v-model="$context.checkbox"/>
			</div>
			<div v-for="column in columns" :key="column['key']">
				<span v-if="!column['sortable']">{{ column['title'] }}</span>
				<a v-if="column['sortable']" :class="{ 'current': sort['column'] == column['key'], 'descending': sort['descending'] }" @click="Sort(column['key'])"><i class="fas fa-sort-up"></i><i class="fas fa-sort-down"></i>{{ column['title'] }}</a>
			</div>
		</div>
		<div>
			<div v-for="row in rows" :key="row['id']">
				<div>
					<Checkbox :id="row['id']" v-model="$context.checkboxes[row['id']]"/>
				</div>
				<div v-for="column in columns" :key="column['key']">
					<span v-if="!column['action']">{{ row[column['key']] }}</span>
					<a v-if="column['action']"  @click.prevent="column.action(row)"><i :class="column['icon']"></i>{{ row[column['key']] }}</a>
				</div>
			</div>
		</div>
	</div>
</template>
