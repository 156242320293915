import Axios from 'axios'

const Query = async (payload) => {
	const { data } = await Axios.post('users', payload)

	return data
}

const Add = async (payload) => {
	const { data } = await Axios.post('users/add', payload)

	return data
}

export default { Query, Add }
