<script setup>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps(['modelValue', 'options', 'placeholder', 'label'])
const emit = defineEmits(['update:modelValue', 'change'])
const Select = ref()

const Change = (value) => {
	emit('update:modelValue', value)
	emit('change', value)

	Toggle(false)
}

const Toggle = (status) => {
	if (status) {
		Select.value.classList.add('open')
		Select.value.querySelector(':scope input').value = ''

		setTimeout(() => Select.value.querySelector(':scope input').focus(), 10)
	} else {
		Select.value.classList.remove('open')
	}

	for (let value in props.options) {
		Select.value.querySelector(':scope [data-value="' + value + '"]').style.display = 'block'
	}
}

const Search = (string) => {
	const $RegExp = new RegExp(string, 'i')

	for (let value in props.options) {
		const Element = Select.value.querySelector(':scope [data-value="' + value + '"]')

		Element.style.display = 'block'

		if (!$RegExp.test(props.options[value])) {
			Element.style.display = 'none'
		}
	}
}

onClickOutside(Select, () => Toggle(false))
</script>

<template>
	<div ref="Select" class="select">
		<div v-if="label">
			<span>{{ label }}</span>
		</div>
		<div>
			<div>
				<span v-show="placeholder && !modelValue" @click.self="Toggle(true)">{{ placeholder }}</span>
				<span v-show="!modelValue" @click="Toggle(true)"><i class="fa-solid fa-caret-down"></i></span>
				<span v-show="modelValue" @click="Change(null)"><i class="fa-solid fa-xmark"></i></span>
				<span v-show="modelValue" @click.self="Toggle(true)">{{ options[modelValue] }}</span>
			</div>
			<div>
				<div>
					<input type="text" @input="Search($event.target.value)">
				</div>
				<div>
					<div v-for="(text, value) of options" :key="value" :data-value="value" @click="Change(value)">{{ text }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
