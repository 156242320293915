import Axios from 'axios'

const Query = async (payload) => {
	const { data } = await Axios.post('uploads', payload)

	return data
}

const Add = async (payload) => {
	const $FormData = new FormData()

	for (let key in payload) {
		$FormData.append(key, payload[key])
	}

	const { data } = await Axios.post('uploads/add', $FormData)

	return data
}

const Delete = async (payload) => {
	const { data } = await Axios.post('uploads/delete', payload)

	return data
}

export default { Query, Add, Delete }
