import { createStore } from 'vuex'
import Controller from '@/controller'

const state = {}
const mutations = {}
const actions = {}
const getters = {}

state.config = null
state.session = null

mutations.config = (state, config) => state.config = config
mutations.session = (state, session) => state.session = session

actions.SetConfig = async ({ commit }) => {
	const config = await Controller.Config()

	commit('config', config)
}

actions.SetSession = async ({ commit }) => {
	const session = await Controller.Session()

	commit('session', session)
}

export default createStore({ state, getters, mutations, actions })
