<script setup>
const emit = defineEmits(['update:modelValue', 'change'])

const Change = (event) => {
	emit('update:modelValue', event.target.checked)
	emit('change', event.target.checked)
}

defineProps(['modelValue', 'id'])
</script>

<template>
	<input type="checkbox" :id="'checkbox_' + id" :checked="modelValue" @change="Change($event)">
	<label :for="'checkbox_' + id"></label>
</template>
