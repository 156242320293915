<script setup>
import { ref, onMounted } from 'vue'
import Controller from '@/controller'
import Button from '@/components/Button.vue'
import TextInput from '@/components/TextInput.vue'

const $context = ref({})

const Download = async () => {
	if (!$context.value.month) return

	const array = $context.value.month.split('.')
	const Dump = await Controller.Invoice.Dump({ 'month': array[0], 'year': array[1] })

	if (Dump) {
		const $Blob = new Blob([Dump])
		const Element = document.createElement('a')

		Element.href = window.URL.createObjectURL($Blob)
		Element.setAttribute('download', 'dump_' + array[0] + '-' + array[1] + '.xlsx')

		document.body.appendChild(Element)

		Element.click()
		Element.remove()
	}
}

onMounted(() => {
	const $Date = new Date()

	$Date.setDate(0)

	$context.value.month = ($Date.getMonth() + 1).toString().padStart(2, '0') + '.' + $Date.getFullYear()
})
</script>

<template>
	<div class="invoices-dump">
		<div>
			<div>
				<TextInput v-model="$context.month" type="text" placeholder="Select month" icon="fa-regular fa-calendar" label="Month" :monthpicker="true"/>
			</div>
			<div>
				<Button text="Download XLSX" class="default" @click="Download()"/>
			</div>
		</div>
	</div>
</template>
