<script setup>
import { ref, computed, watch, onMounted } from 'vue'

const props = defineProps(['modelValue', 'type', 'placeholder', 'label', 'icon', 'class', 'datepicker', 'monthpicker'])
const Input = ref()

const DatePicker = () => {
	const Element = document.createElement('input')

	Element.setAttribute('type', 'date')

	for (let string of ['focus', 'click']) {
		Input.value.addEventListener(string, () => Element.showPicker())
	}

	Element.addEventListener('change', ($Event) => {
		if (!$Event.currentTarget.value) {
			Input.value.value = ''
		} else {
			const $Date = new Date($Event.currentTarget.value)

			Input.value.value = $Date.getDate().toString().padStart(2, '0')
			Input.value.value += '.' + ($Date.getMonth() + 1).toString().padStart(2, '0')
			Input.value.value += '.' + $Date.getFullYear()
		}

		Input.value.dispatchEvent(new Event('input'))
	})

	Input.value.after(Element)

	watch(computed(() => props.modelValue), () => {
		if (props.modelValue) {
			const array = props.modelValue.split('.')

			Element.valueAsDate = new Date(array[2] + '-' + array[1] + '-' + array[0])
		}
	})
}

const MonthPicker = () => {
	const Element = document.createElement('input')

	Element.setAttribute('type', 'month')

	for (let string of ['focus', 'click']) {
		Input.value.addEventListener(string, () => Element.showPicker())
	}

	Element.addEventListener('change', ($Event) => {
		if (!$Event.currentTarget.value) {
			Input.value.value = ''
		} else {
			const $Date = new Date($Event.currentTarget.value)

			Input.value.value = ($Date.getMonth() + 1).toString().padStart(2, '0')
			Input.value.value += '.' + $Date.getFullYear()
		}

		Input.value.dispatchEvent(new Event('input'))
	})

	Input.value.after(Element)

	watch(computed(() => props.modelValue), () => {
		if (props.modelValue) {
			const array = props.modelValue.split('.')

			Element.valueAsDate = new Date(array[1] + '-' + array[0])
		}
	})
}

defineEmits(['update:modelValue'])

onMounted(() => {
	if (props.datepicker) {
		DatePicker()
	}

	if (props.monthpicker) {
		MonthPicker()
	}
})
</script>

<template>
	<div class="text-input" :class="class">
		<div v-if="label">
			<span>{{ label }}</span>
		</div>
		<div>
			<input ref="Input" :value="modelValue" :type="type" :placeholder="placeholder" @input="$emit('update:modelValue', $event.target.value)">
			<i :class="icon" v-if="icon"></i>
		</div>
	</div>
</template>
