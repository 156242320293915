<script setup>
import { ref } from 'vue'
import Layout from '@/components/Layout.vue'
import TabsMenu from '@/components/TabsMenu.vue'

const $context = ref({})

$context.value.menu = [
	{
		to: { name: 'uploads-add' },
		icon: 'fa-solid fa-plus',
		text: 'Add'
	},
	{
		to: { name: 'uploads-table' },
		icon: 'fa-solid fa-list',
		text: 'Uploads'
	}
]
</script>

<template>
	<Layout>
		<div>
			<TabsMenu :menu="[...$context.menu]"/>
		</div>
		<div>
			<router-view/>
		</div>
	</Layout>
</template>
