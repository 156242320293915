<script setup>
import { ref, computed, watch } from 'vue'
import Router from '@/router'
import Controller from '@/controller'
import Layout from '@/components/Layout.vue'
import TabsMenu from '@/components/TabsMenu.vue'

const $context = ref({})

const HandleMenu = async () => {
	if (typeof Router.currentRoute.value.params['ID'] == 'undefined') {
		return $context.value.additional = []
	}

	const Client = await Controller.Client.Client(Router.currentRoute.value.params['ID'])

	$context.value.additional = [
		{
			icon: 'fa-solid fa-chevron-right'
		},
		{
			to: { name: 'clients-client', params: { 'ID': Client['id'] }},
			icon: 'fa-regular fa-pen-to-square',
			text: Client['name']
		}
	]
}

$context.value.menu = [
	{
		to: { name: 'clients-add' },
		icon: 'fa-solid fa-plus',
		text: 'Add'
	},
	{
		to: { name: 'clients-table' },
		icon: 'fa-solid fa-list',
		text: 'Clients'
	}
]

$context.value.additional = []

watch(computed(() => Router.currentRoute.value.params['ID']), () => HandleMenu(), { immediate: true })
</script>

<template>
	<Layout>
		<div>
			<TabsMenu :menu="[...$context.menu, ...$context.additional]"/>
		</div>
		<div>
			<router-view/>
		</div>
	</Layout>
</template>
