import { createRouter, createWebHistory } from 'vue-router'
import Users from '@/views/Users.vue'
import UsersTable from '@/views/UsersTable.vue'
import UsersAdd from '@/views/UsersAdd.vue'
import Clients from '@/views/Clients.vue'
import ClientsTable from '@/views/ClientsTable.vue'
import ClientsAdd from '@/views/ClientsAdd.vue'
import Client from '@/views/Client.vue'
import Invoices from '@/views/Invoices.vue'
import InvoicesTable from '@/views/InvoicesTable.vue'
import InvoicesAdd from '@/views/InvoicesAdd.vue'
import Invoice from '@/views/Invoice.vue'
import InvoicesDump from '@/views/InvoicesDump.vue'
import Uploads from '@/views/Uploads.vue'
import UploadsTable from '@/views/UploadsTable.vue'
import UploadsAdd from '@/views/UploadsAdd.vue'

const routes = [
	{
		path: '/',
		component: Clients,
		children: [
			{
				path: '',
				name: 'clients-table',
				component: ClientsTable
			},
			{
				path: 'add',
				name: 'clients-add',
				component: ClientsAdd
			},
			{
				path: ':ID',
				name: 'clients-client',
				component: Client
			}
		]
	},
	{
		path: '/invoices',
		component: Invoices,
		children: [
			{
				path: '',
				name: 'invoices-table',
				component: InvoicesTable
			},
			{
				path: 'add',
				name: 'invoices-add',
				component: InvoicesAdd
			},
			{
				path: 'dublicate/:ID',
				name: 'invoices-dublicate',
				component: InvoicesAdd
			},
			{
				path: ':ID',
				name: 'invoices-invoice',
				component: Invoice
			},
			{
				path: 'dump',
				name: 'invoices-dump',
				component: InvoicesDump
			}
		]
	},
	{
		path: '/users',
		component: Users,
		children: [
			{
				path: '',
				name: 'users-table',
				component: UsersTable
			},
			{
				path: 'add',
				name: 'users-add',
				component: UsersAdd
			}
		]
	},
	{
		path: '/uploads',
		component: Uploads,
		children: [
			{
				path: '',
				name: 'uploads-table',
				component: UploadsTable
			},
			{
				path: 'add',
				name: 'uploads-add',
				component: UploadsAdd
			}
		]
	}
]

export default createRouter({ history: createWebHistory(), routes })
