<script setup>
import { ref, onMounted } from 'vue'
import Router from '@/router'
import Controller from '@/controller'
import InvoiceForm from '@/components/InvoiceForm.vue'

const Form = ref()

const Update = async () => {
	const { Invoice, error } = await Controller.Invoice.Update(Router.currentRoute.value.params['ID'], { ...Form.value.$context.data })

	Form.value.$context.error = error

	if (Invoice) {
		Form.value.$context.success = 'Invoice updated.'
	}
}

onMounted(async () => {
	const Invoice = await Controller.Invoice.Invoice(Router.currentRoute.value.params['ID'])

	Form.value.$context.data = Invoice
	Form.value.$context.data['client'] = Invoice.Client['id']
})
</script>

<template>
	<div class="invoice">
		<InvoiceForm ref="Form" :button="{ text: 'Update invoice' }" @submit="Update()"/>
	</div>
</template>
