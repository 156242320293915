<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import Router from '@/router'
import Controller from '@/controller'
import InvoiceForm from '@/components/InvoiceForm.vue'

const Form = ref()

const Add = async () => {
	const { Invoice, error } = await Controller.Invoice.Add({ ...Form.value.$context.data })

	Form.value.$context.error = error

	if (Invoice) {
		Router.push({ name: 'invoices-invoice', params: { 'ID': Invoice['id'] }})
	}
}

const PreviewIncrement = async () => {
	if (!Form.value.$context.data['group']) {
		return Form.value.$context.data['number'] = ''
	}

	const params = {
		'sort': {
			'column': 'number',
			'descending': true
		},
		'pagination': {
			'items': -1,
			'page': 1
		},
		'filters': {
			'group': Form.value.$context.data['group']
		}
	}

	const { rows } = await Controller.Invoice.Query(params)

	if (typeof rows[0] !== 'undefined') {
		Form.value.$context.data['number'] = rows[0]['number'] + 1
	} else {
		Form.value.$context.data['number'] = 1
	}
}

const InitData = async () => {
	if (typeof Router.currentRoute.value.params['ID'] !== 'undefined') {
		const Invoice = await Controller.Invoice.Invoice(Router.currentRoute.value.params['ID'])

		Form.value.$context.data = Invoice
		Invoice['client'] = Invoice.Client['id']

		const $Date = new Date
		let formatted = ''

		formatted = $Date.getDate().toString().padStart(2, '0')
		formatted += '.' + ($Date.getMonth() + 1).toString().padStart(2, '0')
		formatted += '.' + $Date.getFullYear()

		Form.value.$context.data['issue_date'] = formatted

		$Date.setDate($Date.getDate() + 2 * 7)

		formatted = $Date.getDate().toString().padStart(2, '0')
		formatted += '.' + ($Date.getMonth() + 1).toString().padStart(2, '0')
		formatted += '.' + $Date.getFullYear()

		Form.value.$context.data['due_date'] = formatted

		return
	}

	Form.value.$context.data = {
		'group': null,
		'electronic': true,
		'number': '',
		'client': null,
		'issue_date': '',
		'due_date': '',
		'vat': true,
		'comment': '',
		'reference': null,
		'items': [
			{ 'name': '', 'quantity': 1, 'price': 0 }
		]
	}
}

onMounted(() => {
	watch(computed(() => Router.currentRoute.value.params['ID']), () => InitData(), { immediate: true })
	watch(computed(() => Form.value.$context.data['group']), () => PreviewIncrement())
})
</script>

<template>
	<div class="invoice-add">
		<InvoiceForm ref="Form" :button="{ text: 'Add invoice' }" @submit="Add()"/>
	</div>
</template>
