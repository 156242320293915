import Axios from 'axios'
import Store from '@/store'
import User from './modules/User'
import Client from './modules/Client'
import Invoice from './modules/Invoice'
import Upload from './modules/Upload'

const Config = async () => {
	const config = {
		'client_types': {},
		'legal_prefixes': {},
		'invoice_groups': {},
		'invoice_reference': {},
		'banks': {},
		'switf': {}
	}

	const { data } = await Axios.get('config')

	for (let value of data['client_types']) {
		config['client_types'][value] = value
	}

	for (let value of data['legal_prefixes']) {
		config['legal_prefixes'][value[0]] = value[1]
	}

	for (let value of data['invoice_groups']) {
		config['invoice_groups'][value] = value
	}

	for (let value of data['invoice_reference']) {
		config['invoice_reference'][value] = value
	}

	for (let i in data['banks']) {
		config['banks'][i] = data['banks'][i][1]
	}

	for (let i in data['banks']) {
		config['switf'][i] = data['banks'][i][0]
	}

	return config
}

const Session = async () => {
	if (!Axios.defaults.headers.common['Authorization']) return

	const { data } = await Axios.get('session')

	return data
}

const Login = async (email, password) => {
	const { data } = await Axios.post('login', { email, password })

	if (data['token']) {
		Axios.defaults.headers.common['Authorization'] = 'Bearer ' + data['token']

		$cookies.set('token', data['token'])

		Store.dispatch('SetSession')
	}

	return data['error']
}

const Logout = async () => {
	await Axios.post('logout')

	delete Axios.defaults.headers.common['Authorization']

	$cookies.remove('token')

	Store.dispatch('SetSession')
}

export default { User, Client, Invoice, Upload, Config, Session, Login, Logout }
