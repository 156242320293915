<script setup>
import { ref, onMounted } from 'vue'
import Controller from '@/controller'
import Button from '@/components/Button.vue'
import Message from '@/components/Message.vue'
import TextInput from '@/components/TextInput.vue'
import Select from '@/components/Select.vue'
import FileInput from '@/components/FileInput.vue'

const emit = defineEmits(['submit'])
const $context = ref({})

const Submit = () => {
	$context.value.error = null
	$context.value.success = null

	if (!$context.value.data['name']) {
		return $context.value.error = 'Name is required.'
	}

	if (!$context.value.data['file']) {
		return $context.value.error = 'File is required.'
	}

	emit('submit')
}

$context.value.data = {
	'name': '',
	'client_id': null,
	'file': null
}

$context.value.error = null
$context.value.success = null
$context.value.clients = {}

defineProps(['button'])
defineExpose({ $context })

onMounted(async () => $context.value.clients = await Controller.Client.List())
</script>

<template>
	<div class="upload-form">
		<div>
			<TextInput v-model="$context.data['name']" type="text" label="Name"/>
		</div>
		<div>
			<Select v-model="$context.data['client_id']" :options="$context.clients" label="Client" placeholder="Select client"/>
		</div>
		<div>
			<FileInput v-model="$context.data['file']" label="File" text="Choose file" id="file"/>
		</div>
		<div>
			<Message :properties="{ class: 'message-error', icon: 'fa-solid fa-triangle-exclamation', message: $context.error }"/>
			<Message :properties="{ class: 'message-success', icon: 'fa-regular fa-circle-check', message: $context.success }"/>
		</div>
		<div>
			<Button :text="button.text" class="default" @click="Submit()"/>
		</div>
	</div>
</template>
