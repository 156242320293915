<script setup>
import { ref, onMounted } from 'vue'
import Store from '@/store'
import Controller from '@/controller'
import Button from '@/components/Button.vue'
import Message from '@/components/Message.vue'
import DataInput from '@/components/DataInput.vue'

const emit = defineEmits(['submit'])
const $context = ref({})

const Push = () => {
	$context.value.data['items'].push({ 'name': '', 'quantity': 1, 'price': 0 })
}

const Delete = (i) => {
	if ($context.value.data['items'].length == 1) return

	$context.value.data['items'] = $context.value.data['items'].slice(0, i).concat($context.value.data['items'].slice(i + 1))
}

const Submit = () => {
	$context.value.error = null
	$context.value.success = null

	if (!$context.value.data['group']) {
		return $context.value.error = 'Group is required.'
	}

	if (!$context.value.data['client']) {
		return $context.value.error = 'Client is required.'
	}

	emit('submit')
}

$context.value.inputs = {
	'group': {
		'type': 'select',
		'placeholder': 'Select group',
		'label': 'Group:',
		'options': Store.state.config['invoice_groups']
	},
	'electronic': {
		'type': 'checkbox',
		'label': 'Electronic:'
	},
	'number': {
		'type': 'text',
		'label': 'Number:',
		'placeholder': 'auto',
		'icon': 'fa-solid fa-hashtag',
		'class': 'disabled'
	},
	'client': {
		'type': 'select',
		'label': 'Client:',
		'placeholder': 'Select client',
		'options': {}
	},
	'issue_date': {
		'type': 'text',
		'label': 'Issue date:',
		'icon': 'fa-regular fa-calendar',
		'datepicker': true
	},
	'due_date': {
		'type': 'text',
		'label': 'Due date:',
		'icon': 'fa-regular fa-calendar',
		'datepicker': true
	},
	'vat': {
		'type': 'checkbox',
		'label': 'VAT:'
	},
	'comment': {
		'type': 'text',
		'label': 'Comment:'
	},
	'reference': {
		'type': 'select',
		'placeholder': 'Select reference',
		'label': 'Reference:',
		'options': Store.state.config['invoice_reference']
	}
}

$context.value.data = {
	'group': null,
	'electronic': false,
	'number': '',
	'client': null,
	'issue_date': '',
	'due_date': '',
	'vat': false,
	'comment': '',
	'reference': null,
	'items': [
		{ 'name': '', 'quantity': 1, 'price': 0 }
	]
}

$context.value.error = null
$context.value.success = null

defineProps(['button'])
defineExpose({ $context })

onMounted(async () => $context.value.inputs['client']['options'] = await Controller.Client.List())
</script>

<template>
	<div class="invoice-form">
		<div>
			<DataInput :inputs="$context.inputs" :data="$context.data"/>
		</div>
		<div>
			<div class="items-input">
				<div>
					<div>Name</div>
					<div>Quantity</div>
					<div>Price</div>
				</div>
				<div>
					<div v-for="(value, i) of $context.data['items']" :key="i">
						<div>
							<input type="text" v-model="$context.data['items'][i]['name']">
						</div>
						<div>
							<input type="number" v-model="$context.data['items'][i]['quantity']">
						</div>
						<div>
							<input type="number" v-model="$context.data['items'][i]['price']">
						</div>
						<div>
							<span @click="Delete(i)"><i class="fa-solid fa-minus"></i></span>
						</div>
					</div>
				</div>
				<div>
					<span @click="Push()"><i class="fa-solid fa-plus"></i></span>
				</div>
			</div>
		</div>
		<div>
			<Message :properties="{ class: 'message-error', icon: 'fa-solid fa-triangle-exclamation', message: $context.error }"/>
			<Message :properties="{ class: 'message-success', icon: 'fa-regular fa-circle-check', message: $context.success }"/>
		</div>
		<div>
			<Button :text="button.text" class="default" @click="Submit()"/>
		</div>
	</div>
</template>
