<script setup>
import { ref, onMounted } from 'vue'
import Router from '@/router'
import Controller from '@/controller'
import ClientForm from '@/components/ClientForm.vue'

const Form = ref()

const Update = async () => {
	const { Client, error } = await Controller.Client.Update(Router.currentRoute.value.params['ID'], { ...Form.value.$context.data })

	Form.value.$context.error = error

	if (Client) {
		Form.value.$context.success = 'Client updated.'
	}
}

onMounted(async () => {
	const Client = await Controller.Client.Client(Router.currentRoute.value.params['ID'])

	Form.value.$context.data = Client
})
</script>

<template>
	<div class="client">
		<ClientForm ref="Form" :button="{ text: 'Update client' }" @submit="Update()"/>
	</div>
</template>
