<script setup>
import { ref } from 'vue'
import Router from '@/router'
import Controller from '@/controller'
import Message from '@/components/Message.vue'
import TextInput from '@/components/TextInput.vue'
import Button from '@/components/Button.vue'

const $context = ref({})

const Add = async () => {
	$context.value.error = null

	if ($context.value.data['password'].length < 8) {
		return $context.value.error = 'Password must be at least 8 characters.'
	}

	if ($context.value.data['password'] !== $context.value.data['repeat_password']) {
		return $context.value.error = 'Passwords do not match.'
	}

	const { User, error } = await Controller.User.Add({ ...$context.value.data })

	$context.value.error = error

	if (User) {
		Router.push({ name: 'users-table' })
	}
}

$context.value.data = {
	'name': '',
	'email': '',
	'password': '',
	'repeat_password': ''
}

$context.value.error = null
</script>

<template>
	<div class="user-add">
		<div>
			<div>
				<TextInput v-model="$context.data['name']" type="text" label="Name"/>
			</div>
			<div>
				<TextInput v-model="$context.data['email']" type="text" label="Email"/>
			</div>
			<div>
				<TextInput v-model="$context.data['password']" type="password" label="Password" icon="fas fa-key"/>
			</div>
			<div>
				<TextInput v-model="$context.data['repeat_password']" type="password" label="Repeat password" icon="fas fa-key"/>
			</div>
			<div>
				<Message :properties="{ class: 'message-error', icon: 'fa-solid fa-triangle-exclamation', message: $context.error }"/>
			</div>
			<div>
				<Button @click="Add()" class="default" text="Add User"/>
			</div>
		</div>
	</div>
</template>
