<script setup>
import { ref } from 'vue'
import Router from '@/router'
import Controller from '@/controller'
import ClientForm from '@/components/ClientForm.vue'

const Form = ref()

const Add = async () => {
	const { Client, error } = await Controller.Client.Add({ ...Form.value.$context.data })

	Form.value.$context.error = error

	if (Client) {
		Router.push({ name: 'clients-client', params: { 'ID': Client['id'] }})
	}
}
</script>

<template>
	<div class="client-add">
		<ClientForm ref="Form" :button="{ text: 'Add client' }" @submit="Add()"/>
	</div>
</template>
