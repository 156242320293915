<script setup>
import { ref } from 'vue'
import Controller from '@/controller'
import Message from '@/components/Message.vue'
import TextInput from '@/components/TextInput.vue'
import Button from '@/components/Button.vue'

const $context = ref({})

const Submit = async () => {
	$context.value.error = await Controller.Login($context.value.fields['email'], $context.value.fields['password'])
}

$context.value.fields = {
	'email': '',
	'password': ''
}
</script>

<template>
	<div class="login">
		<div>
			<div>
				<TextInput v-model="$context.fields['email']" type="email" placeholder="Email" icon="fas fa-user" class="alternative"/>
			</div>
			<div>
				<TextInput v-model="$context.fields['password']" type="password" placeholder="Password" icon="fas fa-key" class="alternative"/>
			</div>
			<div v-if="$context.error">
				<Message :properties="{ class: 'message-error', icon: 'fa-solid fa-triangle-exclamation', message: $context.error }"/>
			</div>
			<div>
				<Button @click="Submit()" class="default" text="Login" icon="fas fa-sign-in"/>
			</div>
		</div>
	</div>
</template>
