<script setup>
import { ref, onMounted } from 'vue'
import Router from '@/router'
import Controller from '@/controller'
import Select from '@/components/Select.vue'
import ArchiveTable from '@/components/ArchiveTable.vue'

const $context = ref({})

const Load = async () => {
	const { rows, last } = await Controller.Invoice.Query({ ...$context.value.params, 'page': $context.value.params['pagination']['page'] })

	$context.value.rows = rows
	$context.value.params['pagination']['last'] = last
}

$context.value.columns = [
	{
		'key': 'number',
		'title': 'Number',
		'sortable': true,
		'icon': 'fa-regular fa-pen-to-square',
		action: (row) => {
			Router.push({ name: 'invoices-invoice', params: { 'ID': row['id'] }})
		}
	},
	{
		'key': 'group',
		'title': 'Group',
		'sortable': false
	},
	{
		'key': 'client_name',
		'title': 'Client',
		'sortable': false
	},
	{
		'key': 'issue_date',
		'title': 'Issue date',
		'sortable': false
	},
	{
		'key': 'due_date',
		'title': 'Due date',
		'sortable': false
	}
]

$context.value.params = {
	'sort': {
		'column': 'number',
		'descending': true
	},
	'pagination': {
		'items': 20,
		'page': 1
	},
	'search': '',
	'filters': {
		'client_id': null,
		'group': null
	}
}

$context.value.actions = {
	'delete': {
		'name': 'Delete',
		action: (selected) => {
			if (confirm('Delete selected items?')) {
				console.log('delete', selected)
			}
		}
	}
}

$context.value.rows = []
$context.value.clients = []

onMounted(async () => $context.value.clients = await Controller.Client.List())
</script>

<template>
	<ArchiveTable :columns="$context.columns" :rows="$context.rows" :params="$context.params" :actions="$context.actions" @change="Load()">
		<Select v-model="$context.params['filters']['group']" :options="$store.state.config['invoice_groups']" placeholder="Filter group"/>
		<Select v-model="$context.params['filters']['client_id']" :options="$context.clients" placeholder="Filter client"/>
	</ArchiveTable>
</template>
