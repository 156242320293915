<script setup>
import { ref } from 'vue'
import Router from '@/router'
import Controller from '@/controller'
import Select from '@/components/Select.vue'
import ArchiveTable from '@/components/ArchiveTable.vue'

const $context = ref({})

const Load = async () => {
	const { rows, last } = await Controller.Client.Query({ ...$context.value.params, 'page': $context.value.params['pagination']['page'] })

	$context.value.rows = rows
	$context.value.params['pagination']['last'] = last
}

$context.value.columns = [
	{
		'key': 'name',
		'title': 'Name',
		'sortable': true,
		'icon': 'fa-regular fa-pen-to-square',
		action: (row) => {
			Router.push({ name: 'clients-client', params: { 'ID': row['id'] }})
		}
	},
	{
		'key': 'legal_prefix',
		'title': 'Legal Prefix',
		'sortable': false
	},
	{
		'key': 'email',
		'title': 'Email',
		'sortable': false
	},
	{
		'key': 'phone',
		'title': 'Phone',
		'sortable': false
	}
]

$context.value.params = {
	'sort': {
		'column': 'name',
		'descending': false
	},
	'pagination': {
		'items': 20,
		'page': 1
	},
	'search': '',
	'filters': {
		'type': null,
		'legal_prefix': null
	}
}

$context.value.actions = {
	'delete': {
		'name': 'Delete',
		action: (selected) => {
			if (confirm('Delete selected items?')) {
				console.log('delete', selected)
			}
		}
	}
}

$context.value.rows = []
</script>

<template>
	<ArchiveTable :columns="$context.columns" :rows="$context.rows" :params="$context.params" :actions="$context.actions" @change="Load()">
		<Select v-model="$context.params['filters']['legal_prefix']" :options="$store.state.config['legal_prefixes']" placeholder="Filter legal prefix"/>
		<Select v-model="$context.params['filters']['type']" :options="$store.state.config['client_types']" placeholder="Filter type"/>
	</ArchiveTable>
</template>
