<script setup>
import { ref, computed, watch } from 'vue'
import Router from '@/router'
import Controller from '@/controller'
import Layout from '@/components/Layout.vue'
import TabsMenu from '@/components/TabsMenu.vue'

const $context = ref({})

const OpenPrintable = async (Invoice) => {
	const Printable = await Controller.Invoice.Printable(Invoice['id'])

	if (Printable) {
		const $Blob = new Blob([Printable], { type: 'application/pdf' })

		window.open(window.URL.createObjectURL($Blob), '_blank')
	}
}

const DownloadPrintable = async (Invoice) => {
	const Printable = await Controller.Invoice.Printable(Invoice['id'])

	if (Printable) {
		const $Blob = new Blob([Printable])
		const Element = document.createElement('a')

		Element.href = window.URL.createObjectURL($Blob)
		Element.setAttribute('download', Invoice['group'] + Invoice['number'] + ' ' + Invoice.Client['name'] + '.pdf')

		document.body.appendChild(Element)

		Element.click()
		Element.remove()
	}
}

const HandleMenu = async () => {
	if (typeof Router.currentRoute.value.params['ID'] == 'undefined') {
		return $context.value.additional = []
	}

	const Invoice = await Controller.Invoice.Invoice(Router.currentRoute.value.params['ID'])

	$context.value.additional = [
		{
			icon: 'fa-solid fa-chevron-right'
		},
		{
			to: { name: 'invoices-invoice', params: { 'ID': Invoice['id'] }},
			icon: 'fa-regular fa-pen-to-square',
			text: Invoice.Client['name'] + ' ' + Invoice['group'] + Invoice['number']
		},
		{
			icon: 'fa-solid fa-chevron-right'
		},
		{
			icon: 'fa-regular fa-file-pdf',
			text: 'Preview',
			action: () => OpenPrintable(Invoice)
		},
		{
			icon: 'fa-solid fa-arrow-turn-down',
			text: 'Download',
			action: () => DownloadPrintable(Invoice)
		},
		{
			to: { name: 'invoices-dublicate', params: { 'ID': Invoice['id'] }},
			icon: 'fa-regular fa-copy',
			text: 'Dublicate'
		}
	]
}

$context.value.menu = [
	{
		to: { name: 'invoices-add' },
		icon: 'fa-solid fa-plus',
		text: 'Add'
	},
	{
		to: { name: 'invoices-dump' },
		icon: 'fa-solid fa-download',
		text: 'Dump'
	},
	{
		to: { name: 'invoices-table' },
		icon: 'fa-solid fa-list',
		text: 'Invoices'
	}
]

$context.value.additional = []

watch(computed(() => Router.currentRoute.value.params['ID']), () => HandleMenu(), { immediate: true })
</script>

<template>
	<Layout>
		<div>
			<TabsMenu :menu="[...$context.menu, ...$context.additional]"/>
		</div>
		<div>
			<router-view/>
		</div>
	</Layout>
</template>
