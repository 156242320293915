<script setup>
import { ref } from 'vue'
import Router from '@/router'
import Controller from '@/controller'
import UploadForm from '@/components/UploadForm.vue'

const Form = ref()

const Add = async () => {
	const { Upload, error } = await Controller.Upload.Add({ ...Form.value.$context.data })

	Form.value.$context.error = error

	if (Upload) {
		Router.push({ name: 'uploads-table' })
	}
}
</script>

<template>
	<div class="upload-add">
		<div>
			<UploadForm ref="Form" :button="{ text: 'Submit upload' }" @submit="Add()"/>
		</div>
	</div>
</template>
